import React from "react"

// Components
import Seo from "components/seo"
import Hero from "../../components/hero"

// Page Sections
import Library from "../screens/press-kit/library"

const PressKit = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Press Kit">
        <p>
          Get the assets you need to talk about Visby here.<br />
          For additional information, please contact <a href="mailto:press@visby.com">press@visby.com</a>.
        </p>
      </Hero>
      <Library />
    </React.Fragment>
  )
}

export default PressKit

export const Head = () => (
  <Seo
        title="Press Kit"
        description="Get the assets you need to talk about Visby here."
        image="/meta/press-kit.jpg"
  />
)
